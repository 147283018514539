import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './MatchPlayers.scss';

const MatchPlayer = ({ players }) => {
  const history = useHistory();
  const { leagueCategory } = useSelector((store) => store.leagueReducer);

  const handleClickPlayer = (playerId) => {
    history.push(`/${leagueCategory}/players/${playerId}`);
  };

  let playersSorted = [];

  if (players?.length) {
    playersSorted = [...players];
    playersSorted.sort((a, b) => {
      if (a.is_starter && !b.is_starter) return -1;
      if (!a.is_starter && b.is_starter) return 1;
      if (a.back_number && b.back_number) {
        if (a.back_number < b.back_number) return -1;
        if (a.back_number > b.back_number) return 1;
      }
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
  }

  return (
    <div className={players?.length ? 'bench-players-wrapper' : 'empty'}>
      {playersSorted?.map((player, index) => {
        if (player.name !== 'Totals' && player.name !== 'ETC')
          return (
            <div
              key={index}
              className='bench-player'
              onClick={() => handleClickPlayer(player.player_id)}
            >
              <span className='bench-back-number'>
                {player.back_number || typeof player.back_number === 'number'
                  ? player.back_number === 100
                    ? '00'
                    : player.back_number
                  : '-'}
              </span>
              <span>{player.name}</span>
            </div>
          );
      })}
    </div>
  );
};

export default MatchPlayer;
