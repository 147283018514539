export default class UserValidator {
  constructor() {
    this.username = '';
    this.password = '';
    this.passwordConfirm = '';
    this.nickname = '';
    this.email = '';
  }

  validateUsername() {
    if (this.username.length < 6 || this.username.length > 30) {
      return false;
    }
    return true;
  }

  validatePassword() {
    if (this.password.length < 8) {
      return false;
    }
    return true;
  }

  validatePasswordConfirm() {
    if (this.password !== this.passwordConfirm) {
      return false;
    }
    return true;
  }

  validateNickname() {
    if (this.nickname.length < 2 || this.nickname.length > 30) {
      return false;
    }
    return true;
  }

  // add regex later
  validateEmail() {
    if (!this.email.includes('@')) {
      return false;
    }
    return true;
  }

  validateUpdateForm(username, nickname) {
    this.username = username;
    this.nickname = nickname;

    if (!this.validateUsername()) {
      alert('User ID must contain between 6 and 30 characters.');
      return false;
    }
    if (!this.validateNickname()) {
      alert('Nickname must contain between 2 and 30 characters.');
      return false;
    }
    return true;
  }

  validateEmailUpdateForm(email) {
    this.email = email;

    if (!this.validateEmail()) {
      alert('Please write a proper email format.');
      return false;
    }
    return true;
  }

  validatePasswordForm(password, passwordConfirm) {
    this.password = password;
    this.passwordConfirm = passwordConfirm;

    if (!this.validatePassword()) {
      alert('Password must be longer than 8 characters');
      return false;
    }

    if (!this.validatePasswordConfirm()) {
      alert('Password and Re-enter Password must be same');
      return false;
    }
    return true;
  }

  validateRegisterForm(username, password, passwordConfirm, nickname, email) {
    if (
      !this.validateUpdateForm(username, nickname) ||
      !this.validateEmailUpdateForm(email) ||
      !this.validatePasswordForm(password, passwordConfirm)
    ) {
      return false;
    }
    return true;
  }
}
