import React, { useState } from 'react';
import SelectPlayersModal from './SelectPlayersModal';
import './SelectPlayersButton.scss';

const SelectPlayersButton = (props) => {
  const [openSelectModal, SetOpenSelectModal] = useState(false);

  const handleOpenSelectModal = () => {
    SetOpenSelectModal(true);
  };

  const handleCloseSelectModal = () => {
    SetOpenSelectModal(false);
  };

  const selectTitle = 'Please select players for the match';
  return (
    <div className='select-players-button-wrapper'>
      <button className='select-players-button' onClick={handleOpenSelectModal}>
        <span style={{ textAlign: 'start' }}>
          Select
          <br />
          Players
        </span>
        <span style={{ fontSize: '1.5rem' }}>{'>'}</span>
      </button>
      <SelectPlayersModal
        open={openSelectModal}
        onClose={handleCloseSelectModal}
        title={selectTitle}
        roster={props.roster}
        matchId={props.matchId}
        onCancel={handleCloseSelectModal}
      />
    </div>
  );
};

export default SelectPlayersButton;
