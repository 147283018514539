import React from 'react';
import './PlayerBannerBadges.scss';

const PlayerBannerBadges = () => {
  return (
    <div className='player-banner-badges-container' style={{ textAlign: 'center' }}>
      Player Badges Coming Soon!
      {/* <div className="banner-badges-title">Team Badges</div>
      <div className="banner-badges-content">TBD</div> */}
    </div>
  );
};

export default PlayerBannerBadges;
