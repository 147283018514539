import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { login } from '../../store/actions/login';
import { useHistory } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import './Login.scss';

const Login = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [openLoader, setOpenLoader] = useState(false);

  const loginHandler = (e) => {
    e.preventDefault();
    setOpenLoader(true);
    dispatch(login(username, password))
      .then(() => {
        if (history.location.pathname === '/register') history.push('/');
      })
      .catch(() => {
        setOpenLoader(false);
        const loginFailText = document.querySelector('.login-fail-text');
        loginFailText.classList.remove('hidden');
      });
  };

  const handleClickFind = () => {
    props.onClick();
    history.push('/find');
  };

  return (
    <div className='account-box hidden'>
      <div className='login-box-wrapper'>
        {openLoader ? (
          <Loader className='loader' type='Oval' color='#ff5722' secondaryColor='#757575' />
        ) : (
          <div className='login-box'>
            <div className='login-text-wrapper'>
              <span className='login-text'>LOGIN</span>
              <span className='login-fail-text hidden'>Incorrect ID or password</span>
            </div>
            <form className='login-form' onSubmit={loginHandler}>
              <div className='username-password'>
                <input placeholder='ID' required onChange={(e) => setUsername(e.target.value)} />
                <input
                  placeholder='Password'
                  required
                  type='password'
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button className='login-button' type='submit'>
                Login
              </button>
            </form>
            <div className='login-others'>
              <span className='find-password' onClick={handleClickFind}>
                Find ID/Password
              </span>
              <Link className='register' to='/register' onClick={props.onClick}>
                Register/Sign up
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
