import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { clearPlayers } from '../../store/actions/player';
import PlayerSearch from '../Player/PlayerSearch';
import PlayerList from './PlayerList';
import SportsBasketballOutlinedIcon from '@mui/icons-material/SportsBasketballOutlined';
import ModalBox from '../Common/ModalBox';
import { cancelConnectionRequest } from '../../store/actions/user';
import './PlayerRequest.scss';

const PlayerRequest = () => {
  const dispatch = useDispatch();
  const { playersWithTeams, isLoading } = useSelector((store) => store.playerReducer);
  const [players, setPlayers] = useState([]);
  // Todo: currently using stats, but need a better api to get { player, [teams] }
  const [clear, setClear] = useState(false);
  const [searchBar, setSearchBar] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleCancelConfirm = () => {
    dispatch(cancelConnectionRequest());
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const cancelTitle = 'Confirm Cancellation';
  const cancelMessage = 'Do you confirm to cancel your pending connection request?';

  useEffect(() => {
    clearSearchBar();
  }, []);

  useEffect(() => {
    if (playersWithTeams && clear) {
      const playerList = [];
      playersWithTeams.forEach((stat) => {
        playerList.push({
          playerId: stat.id,
          player: stat.name,
          teams: stat.teams,
        });
      });
      setPlayers(playerList);
    }
  }, [playersWithTeams]);

  const openSearchBar = () => {
    setSearchBar(true);
  };

  const clearSearchBar = () => {
    dispatch(clearPlayers());
    setClear(true);
  };
  return (
    <div className='request-wrapper'>
      {searchBar ? (
        <div className='search-bar-wrapper'>
          <PlayerSearch />
          <Button variant='text' onClick={clearSearchBar}>
            Clear
          </Button>
        </div>
      ) : (
        <div className='button-connection' onClick={openSearchBar}>
          Request Player Connection To Admin
        </div>
      )}
      {clear && players.length ? (
        <div className='player-list-container'>
          <div className='player-list-message'>
            Click &nbsp;
            <SportsBasketballOutlinedIcon fontSize='large' /> &nbsp; to select yourself and submit!
          </div>
          <PlayerList players={players} isLoading={isLoading} />
        </div>
      ) : (
        <div className='player-request-button-wrapper'>
          <span>Do you want to cancel your pending connection request?</span>
          <div className='button-connection' onClick={() => setOpenModal(true)}>
            Cancel Connection Request
          </div>
        </div>
      )}
      <ModalBox
        open={openModal}
        onClose={handleCloseModal}
        title={cancelTitle}
        message={cancelMessage}
        onCancel={handleCloseModal}
        onConfirm={handleCancelConfirm}
      />
    </div>
  );
};

export default PlayerRequest;
