import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { logOut } from '../../store/actions/login';
import { clearCurrentUserInfo } from '../../store/actions/user';
import './Account.scss';

const Account = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userToken } = useSelector((store) => store.loginReducer);

  const handleLogOut = () => {
    dispatch(logOut());
    dispatch(clearCurrentUserInfo());
    history.go(0);
  };

  return (
    <div className='account-box hidden' id='logged-in' onMouseLeave={props.onMouseLeave}>
      <div className='account-item-wrapper'>
        <div className='account-username-container'>
          <div className='account-username-wrapper'>
            <div className='account-username'>{userToken?.username}</div>
            <div className='account-status'>
              {userToken?.status === 'Inactive' ? (
                <p>
                  Not Verified User.
                  <br />
                  Please Verify Your Email First.
                </p>
              ) : (
                userToken?.auth_level
              )}
            </div>
          </div>
        </div>
        <div id='account-menu'>
          <Link to='/profile'>Go to My page</Link>
          <Link to={`/change/email`}>Change email</Link>
          <Link to={`/change/password`}>Change password</Link>
        </div>
        <div className='account-logout' onClick={handleLogOut}>
          Log Out
        </div>
      </div>
    </div>
  );
};

export default Account;
