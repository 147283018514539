import React from 'react';
import './Schedule.scss';
import LeagueMenuItem from '../components/SubMenuItem';
import { Route, Switch } from 'react-router-dom';
import ScheduleList from './ScheduleList';
import { useDispatch, useSelector } from 'react-redux';
import { getLeagues } from '../store/actions/league';
import { useEffect } from 'react';

const Schedule = () => {
  const dispatch = useDispatch();
  const { leagues, leagueCategory } = useSelector((store) => store.leagueReducer);

  /** same as Home.js */
  useEffect(() => {
    dispatch(getLeagues(leagueCategory));
  }, [leagueCategory]);

  return (
    <div className='schedule-wrapper'>
      <div className='schedule-menu-wrapper'>
        <LeagueMenuItem name='Summary' path={`/${leagueCategory}/Schedules`} exact={true} />
        {leagueCategory !== 'Training'
          ? leagues.map((item, index) => {
              return (
                <LeagueMenuItem
                  name={item.abv}
                  path={`/${leagueCategory}/Schedules/${item.abv}`}
                  key={index}
                />
              );
            })
          : null}
      </div>
      <Switch>
        <Route path='/:category/Schedules' component={ScheduleList} exact={true} />
        <Route path='/:category/Schedules/:league' component={ScheduleList} exact={true} />
      </Switch>
    </div>
  );
};

export default Schedule;
