import React, { useState } from 'react';
import DeletePlayersModal from './DeletePlayersModal';
import './DeletePlayersButton.scss';

const DeletePlayersButton = (props) => {
  const [open, setOpen] = useState(false);

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const title = 'Please select players to delete';
  return (
    <div className='delete-players-button-wrapper'>
      <button className='delete-players-button' onClick={handleOpenModal}>
        <span style={{ textAlign: 'start' }}>
          Delete
          <br />
          Players
        </span>
        <span style={{ fontSize: '1.5rem' }}>{'>'}</span>
      </button>
      <DeletePlayersModal
        open={open}
        onClose={handleCloseModal}
        title={title}
        players={props.players}
        onCancel={handleCloseModal}
      />
    </div>
  );
};

export default DeletePlayersButton;
