import React from 'react';
import './TeamRoster.scss';
import { DataGrid } from '@mui/x-data-grid';
import { teamRosterDefaultHeader } from '../../const/teamDefaultHeader';

const TeamRoster = (props) => {
  return (
    <div className='team-roster-wrapper'>
      <div className='team-roster-container'>
        <span>
          {props.roster
            ? props.roster[0]?.team_name + ' Roster - ' + props.roster[0]?.league_season_name
            : 'No Team Selected'}
        </span>
        <DataGrid
          rows={props.roster ? props.roster : []}
          columns={teamRosterDefaultHeader}
          getRowId={(row) => row.player_id}
          autoHeight={true}
          rowHeight={30}
          pageSize={100}
          rowsPerPageOptions={[100]}
          hideFooter={true}
        />
      </div>
    </div>
  );
};

export default TeamRoster;
