import axios from 'axios';

export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER';
export const GET_MY_PLAYER = 'GET_MY_PLAYER';
export const CLEAR_MY_PLAYER = 'CLEAR_MY_PLAYER';
export const FIND_USERNAME = 'FIND_USERNAME';

export function getCurrentUserInfo(userId) {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/users/${userId}`, {
        headers: {
          Authorization: `bearer ${localStorage.getItem('sketch-token')}`,
        },
      })
      .then((res) => {
        dispatch({
          type: GET_CURRENT_USER,
          data: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function clearCurrentUserInfo() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_CURRENT_USER,
    });
  };
}

export function getMyPlayer(userId) {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/users/${userId}/player`, {
        headers: {
          Authorization: `bearer ${localStorage.getItem('sketch-token')}`,
        },
      })
      .then((res) => {
        dispatch({
          type: GET_MY_PLAYER,
          data: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function updateMyProfile(userId, username, name, nickname, email) {
  return async (dispatch) => {
    const requestBody = {
      username: username,
      name: name,
      nickname: nickname,
      email: email,
    };
    return axios
      .put(`${process.env.REACT_APP_API_PATH}/users/${userId}`, requestBody, {
        headers: {
          Authorization: `bearer ${localStorage.getItem('sketch-token')}`,
        },
      })
      .then((res) => {
        dispatch({
          type: GET_CURRENT_USER,
          data: res.data,
        });
        if (res.data.access_token) {
          localStorage.setItem('sketch-token', res.data.access_token);
        }
        alert('Profile is updated successfully!');
      })
      .catch((err) => {
        console.log(err.response);
        alert(err.response.data.detail);
      });
  };
}

export function updateMyPlayer(userId, weight, height, quote, birthdate, profilePhoto) {
  return async (dispatch) => {
    const requestBody = {
      weight: weight,
      height: height,
      quote: quote,
      birthdate: birthdate,
      profile_photo: profilePhoto,
    };
    return axios
      .put(`${process.env.REACT_APP_API_PATH}/users/${userId}/player`, requestBody, {
        headers: {
          Authorization: `bearer ${localStorage.getItem('sketch-token')}`,
        },
      })
      .then((res) => {
        dispatch({
          type: GET_MY_PLAYER,
          data: res.data,
        });
        alert('Player profile is updated!');
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function verifyEmail(userId) {
  return async () => {
    return axios
      .get(`${process.env.REACT_APP_API_PATH}/users/${userId}/verify`, {
        headers: {
          Authorization: `bearer ${localStorage.getItem('sketch-token')}`,
        },
      })
      .then((res) => {
        localStorage.setItem('sketch-token', res.data.access_token);
        return res;
      })
      .catch((err) => {
        throw err.response;
      });
  };
}

export function requestPlayerConnection(playerId) {
  return async () => {
    const requestBody = {
      player_id: playerId,
    };
    return axios
      .post(`${process.env.REACT_APP_API_PATH}/me/player`, requestBody, {
        headers: {
          Authorization: `bearer ${localStorage.getItem('sketch-token')}`,
        },
      })
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err.response);
        throw err;
      });
  };
}

export function cancelConnectionRequest() {
  return () => {
    axios
      .delete(`${process.env.REACT_APP_API_PATH}/me/player/cancel`, {
        headers: {
          Authorization: `bearer ${localStorage.getItem('sketch-token')}`,
        },
      })
      .then((res) => {
        alert(res.data.message);
      })
      .catch((err) => {
        if (err.response.status === 500) {
          alert('Server Error! Please contact to official@sketchbball.com');
        } else {
          err.response.status === 400
            ? alert(err.response.data.detail)
            : alert(err.response.data.detail[0].msg);
        }
      });
  };
}

export function disconnectMyPlayer() {
  return (dispatch) => {
    return axios
      .delete(`${process.env.REACT_APP_API_PATH}/me/player`, {
        headers: {
          Authorization: `bearer ${localStorage.getItem('sketch-token')}`,
        },
      })
      .then((res) => {
        dispatch({
          type: CLEAR_MY_PLAYER,
          data: res.data,
        });
        alert(res.data.message);
      })
      .catch((err) => {
        if (err.response.status === 500) {
          alert('Server Error! Please contact to official@sketchbball.com.');
        } else {
          err.response.status === 400
            ? alert(err.response.data.detail)
            : alert(err.response.data.detail[0].msg);
        }
      });
  };
}

export function changeMyEmail(userId, newEmail) {
  return async (dispatch) => {
    const requestBody = {
      email: newEmail,
    };
    return axios
      .patch(`${process.env.REACT_APP_API_PATH}/users/${userId}/email`, requestBody, {
        headers: {
          Authorization: `bearer ${localStorage.getItem('sketch-token')}`,
        },
      })
      .then((res) => {
        dispatch({
          type: GET_CURRENT_USER,
          data: res.data,
        });
        alert(
          'Your email is changed successfully! Please check your email inbox to verify your new email address again.',
        );
        return true;
      })
      .catch((err) => {
        console.log(err.response);
        err.response.status === 400
          ? alert(err.response.data.detail)
          : alert(err.response.data.detail[0].msg);
      });
  };
}

export function changeMyPassword(userId, oldPassword, newPassword) {
  return async (dispatch) => {
    const requestBody = {
      old_password: oldPassword,
      new_password: newPassword,
    };
    return axios
      .patch(`${process.env.REACT_APP_API_PATH}/users/${userId}/password`, requestBody, {
        headers: {
          Authorization: `bearer ${localStorage.getItem('sketch-token')}`,
        },
      })
      .then(() => {
        dispatch({
          type: CLEAR_CURRENT_USER,
        });
        alert('Password is updated successfully! Please Login again.');
        return true;
      })
      .catch((err) => {
        console.log(err.response);
        err.response.status === 400
          ? alert(err.response.data.detail)
          : alert(err.response.data.detail[0].msg);
      });
  };
}

export function findMyUsername(email) {
  return () => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/find/username?email=${email}`, {
        headers: {
          Authorization: `bearer ${localStorage.getItem('sketch-token')}`,
        },
      })
      .then((res) => {
        alert(`Your user ID is ${res.data.username}.`);
      })
      .catch((err) => {
        console.log(err.response);
        err.response.status === 400
          ? alert(err.response.data.detail)
          : alert('Unidentified Error Happened. Please contact to official@sketchbball.com');
      });
  };
}

export function sendPasswordReset(email, username) {
  return () => {
    const requestBody = {
      email: [email],
      username: username,
    };
    axios
      .post(`${process.env.REACT_APP_API_PATH}/find/password`, requestBody, {
        headers: {
          Authorization: `bearer ${localStorage.getItem('sketch-token')}`,
        },
      })
      .then((res) => {
        alert(`Password reset: ${res.data.message}.`);
        window.location.reload(false);
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.status === 500) {
          alert('Server Error! Please contact to official@sketchbball.com');
        } else {
          err.response.status === 400
            ? alert(err.response.data.detail)
            : alert(err.response.data.detail[0].msg);
        }
      });
  };
}

export function resetPassword(userId, newPassword) {
  return () => {
    const requestBody = {
      new_password: newPassword,
    };
    axios
      .patch(`${process.env.REACT_APP_API_PATH}/users/${userId}/password/reset`, requestBody, {
        headers: {
          Authorization: `bearer ${localStorage.getItem('sketch-token')}`,
        },
      })
      .then((res) => {
        alert(res.data.message);
        window.location.href = '/';
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.status === 500) {
          alert('Server Error! Please contact to official@sketchbball.com');
        } else {
          err.response.status === 400
            ? alert(err.response.data.detail)
            : alert(err.response.data.detail[0].msg);
        }
      });
  };
}
