import axios from 'axios';

export const GET_MATCH_DETAILS = 'GET_MATCH_DETAILS';
export const GET_MATCH_DETAILS_SUCCESS = 'GET_MATCH_DETAILS_SUCCESS';
export const GET_MATCH_DETAILS_FAIL = 'GET_MATCH_DETAILS_FAIL';

export const GET_HOME_TEAM_ROSTER = 'GET_HOME_TEAM_ROSTER';
export const GET_AWAY_TEAM_ROSTER = 'GET_AWAY_TEAM_ROSTER';

export const GET_HOME_CAPTAINS = 'GET_HOME_CAPTAINS';
export const GET_AWAY_CAPTAINS = 'GET_AWAY_CAPTAINS';
export const GET_HOME_COACHES = 'GET_HOME_COACHES';
export const GET_AWAY_COACHES = 'GET_AWAY_COACHES';

export const GET_MATCH_PHOTOS = 'GET_MATCH_PHOTOS';
export const GET_MATCH_PHOTOS_SUCCESS = 'GET_MATCH_PHOTOS_SUCCESS';
export const GET_MATCH_PHOTOS_FAIL = 'GET_MATCH_PHOTOS_FAIL';

export const CLEAR_MATCH = 'CLEAR_MATCH';

export function getMatchDetails(id) {
  return (dispatch) => {
    dispatch({ type: GET_MATCH_DETAILS });
    axios
      .get(`${process.env.REACT_APP_API_PATH}/matches/${id}`)
      .then((res) => {
        dispatch({ type: GET_MATCH_DETAILS_SUCCESS, data: res.data });
      })
      .catch((err) => {
        // TODO. toast some messages
        dispatch({ type: GET_MATCH_DETAILS_FAIL });
        console.log(err);
      });
  };
}

export function getHomeTeamRoster(homeTeamId, seasonId) {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/teams/${homeTeamId}/seasons/${seasonId}/players`)
      .then((res) => {
        dispatch({ type: GET_HOME_TEAM_ROSTER, data: res.data });
      })
      .catch((err) => {
        // TODO. toast some messages
        console.log(err);
      });
  };
}

export function getAwayTeamRoster(awayTeamId, seasonId) {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/teams/${awayTeamId}/seasons/${seasonId}/players`)
      .then((res) => {
        dispatch({ type: GET_AWAY_TEAM_ROSTER, data: res.data });
      })
      .catch((err) => {
        // TODO. toast some messages
        console.log(err);
      });
  };
}

export function getHomeCaptains(homeTeamId) {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/teams/${homeTeamId}/captains`)
      .then((res) => {
        dispatch({ type: GET_HOME_CAPTAINS, data: res.data });
      })
      .catch((err) => {
        // TODO. toast some messages
        console.log(err);
      });
  };
}

export function getAwayCaptains(awayTeamId) {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/teams/${awayTeamId}/captains`)
      .then((res) => {
        dispatch({ type: GET_AWAY_CAPTAINS, data: res.data });
      })
      .catch((err) => {
        // TODO. toast some messages
        console.log(err);
      });
  };
}

export function getHomeCoaches(homeTeamId) {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/teams/${homeTeamId}/coaches`)
      .then((res) => {
        dispatch({ type: GET_HOME_COACHES, data: res.data });
      })
      .catch((err) => {
        // TODO. toast some messages
        console.log(err);
      });
  };
}

export function getAwayCoaches(awayTeamId) {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/teams/${awayTeamId}/coaches`)
      .then((res) => {
        dispatch({ type: GET_AWAY_COACHES, data: res.data });
      })
      .catch((err) => {
        // TODO. toast some messages
        console.log(err);
      });
  };
}

export function createMatchstats(players) {
  return () => {
    axios
      .post(`${process.env.REACT_APP_API_PATH}/matchstats`, players, {
        headers: {
          Authorization: 'bearer ' + localStorage.getItem('sketch-token'),
        },
      })
      .then((res) => {
        alert(`${res.data.length} players are successfully added to the match!`);
        window.location.reload(false);
      })
      .catch((err) => {
        // TODO. toast some messages
        console.log(err);
      });
  };
}

export const updateStarters = (starters) => {
  return () => {
    axios
      .post(`${process.env.REACT_APP_API_PATH}/matchstats/starters`, starters, {
        headers: {
          Authorization: `bearer ${localStorage.getItem('sketch-token')}`,
        },
      })
      .then((res) => {
        alert(res.data.detail);
        window.location.reload(false);
      })
      .catch((err) => {
        // TODO. toast some messages
        console.log(err);
      });
  };
};

export const deleteMatchstats = (matchstatIds) => {
  return () => {
    axios
      .delete(`${process.env.REACT_APP_API_PATH}/matchstats`, {
        data: matchstatIds,
        headers: {
          Authorization: `bearer ${localStorage.getItem('sketch-token')}`,
        },
      })
      .then(() => {
        alert('Successfully deleted the players from the match!');
        window.location.reload(false);
      })
      .catch((err) => {
        // TODO. toast some messages
        console.log(err.response);
      });
  };
};

export function getMatchPhotos(id) {
  return (dispatch) => {
    dispatch({ type: GET_MATCH_PHOTOS });
    axios
      .get(`${process.env.REACT_APP_API_PATH}/matches/${id}/images`)
      .then((res) => {
        dispatch({ type: GET_MATCH_PHOTOS_SUCCESS, data: res.data });
      })
      .catch((err) => {
        // TODO. toast some messages
        dispatch({ type: GET_MATCH_PHOTOS_FAIL });
        console.log('Error in Match Photo Loading', err);
      });
  };
}

export function clearMatch() {
  return (dispatch) => {
    dispatch({ type: CLEAR_MATCH });
  };
}
