import React from 'react';
import './YoutubeEmbed.scss';

const YoutubeEmbed = ({ embedId }) => {
  return (
    <div className='youtube-wrapper' key={embedId}>
      <iframe
        width='853'
        height='480'
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
        title='Embedded youtube'
      />
    </div>
  );
};

export default YoutubeEmbed;
