import React, { useEffect } from 'react';
import { leagueCategories } from '../../const/leagueCategories';
import CategoryHeader from './CategoryHeader';
import DefaultHeader from './DefaultHeader';
import './Header.scss';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { validateToken } from '../../store/actions/login';

const Header = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((store) => store.loginReducer);

  useEffect(() => {
    dispatch(validateToken());
  }, [isLoggedIn]);

  return (
    <div className='HeaderWrapper'>
      {leagueCategories.includes(location.pathname.split('/')[1]) ? (
        <CategoryHeader />
      ) : (
        <DefaultHeader />
      )}
    </div>
  );
};

export default Header;
