import axios from 'axios';

export const GET_LEAGUES = 'GET_LEAGUES';

export const GET_LEAGUE_CATEGORY = 'GET_LEAGUE_CATEGORY';

export function getLeagues(leagueCategory) {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/leagues?league_category=${leagueCategory}`)
      .then((res) => {
        dispatch({ type: GET_LEAGUES, data: res.data });
      })
      .catch((err) => {
        // TODO. toast some messages
        console.log(err);
      });
  };
}

export function getLeagueCategory(leagueCategory) {
  return (dispatch) => {
    dispatch({ type: GET_LEAGUE_CATEGORY, data: leagueCategory });
  };
}
