import React from 'react';
import { Box, Modal } from '@mui/material';
import Loader from 'react-loader-spinner';
import './ModalBox.scss';
import modalStyle from '../../const/modalStyle';

const ModalBox = (props) => {
  const isLoading = props.loading;

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Box sx={modalStyle}>
        {isLoading ? (
          <Loader
            className='loader'
            type='Oval'
            color='#ff5722'
            secondaryColor='#757575'
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '2rem 0',
            }}
          />
        ) : (
          <>
            <h3 className='modal-title'>{props.title}</h3>
            <p style={{ lineHeight: 1.5 }}>{props.message}</p>
            <div className='modal-button-wrapper'>
              <div className='button-connection' onClick={props.onCancel}>
                Cancel
              </div>
              <div className='button-connection' onClick={props.onConfirm}>
                Confirm
              </div>
            </div>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default ModalBox;
