import axios from 'axios';

export const GET_POST_DETAIL = 'GET_POST_DETAIL';
export const GET_POST_DETAIL_SUCCESS = 'GET_POST_DETAIL_SUCCESS';
export const GET_POST_DETAIL_FAIL = 'GET_POST_DETAIL_FAIL';

export function getPostDetail(postId) {
  return (dispatch) => {
    dispatch({ type: GET_POST_DETAIL });
    axios
      .get(`${process.env.REACT_APP_API_PATH}/posts/${postId}`)
      .then((res) => {
        dispatch({
          type: GET_POST_DETAIL_SUCCESS,
          data: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: GET_POST_DETAIL_FAIL });
      });
  };
}

export const clearPostDetail = () => {
  return (dispatch) => {
    dispatch({ type: GET_POST_DETAIL_FAIL });
  };
};
