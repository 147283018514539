import React, { useState } from 'react';
import './FindAccountInput.scss';

// This component should be refactored to be general Input Component
const FindAccountInput = (props) => {
  const [firstInput, setFirstInput] = useState('');
  const [secondInput, setSecondInput] = useState('');

  const handleFirstInputChange = (firstInput) => {
    setFirstInput(firstInput);
    props.onFirstInputChange(firstInput);
  };

  const handleSecondInputChange = (secondInput) => {
    setSecondInput(secondInput);
    props.onSecondInputChange(secondInput);
  };

  return (
    <>
      {props.title ? <div className='input-title'>{props.title}</div> : null}
      <div className='input-container'>
        <div className='form-item'>
          <div className='form-item-title'>{props.type}</div>
          <div className='form-item-content'>
            {props.type === 'Name' ? (
              <div className='name-wrapper'>
                <input
                  value={firstInput || ''}
                  required
                  onChange={(e) => handleFirstInputChange(e.target.value)}
                />
                <input
                  value={secondInput || ''}
                  required
                  onChange={(e) => handleSecondInputChange(e.target.value)}
                />
              </div>
            ) : (
              <input
                type={props.type === 'Password' ? 'password' : 'text'}
                value={firstInput || ''}
                required
                onChange={(e) => handleFirstInputChange(e.target.value)}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FindAccountInput;
