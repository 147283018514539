import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PostListItem from '../components/Post/PostListItem';
import { getPosts, getNotices } from '../store/actions/post';
import { getLeagues } from '../store/actions/league';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  IconButton,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import './News.scss';
import PostPagination from '../components/Post/PostPagination';
import NoticeListItem from '../components/Post/NoticeListItem';
import Loader from 'react-loader-spinner';
import FeedbackIcon from '@mui/icons-material/Feedback';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const News = () => {
  const dispatch = useDispatch();
  const { startPage, endPage, isLast, posts, notices, postsLoading } = useSelector(
    (store) => store.postReducer,
  );
  const { leagues, leagueCategory } = useSelector((store) => store.leagueReducer);
  const [currentPage, setCurrentPage] = useState(1);
  const [leagueId, setLeagueId] = useState('Show All');
  const [searchQuery, setSearchQuery] = useState('');

  /** same as Home.js */
  useEffect(() => {
    if (leagueCategory) {
      dispatch(getLeagues(leagueCategory));
      dispatch(getNotices());
    }
  }, [leagueCategory]);

  useEffect(() => {
    dispatch(getPosts(currentPage, leagueId));
  }, [leagueId]);

  const onClickPrev = () => {
    dispatch(getPosts(startPage - 1, leagueId));
    setCurrentPage(startPage - 1);
  };

  const onClickPage = (index) => {
    dispatch(getPosts(startPage + index, leagueId));
    setCurrentPage(startPage + index);
  };

  const onClickNext = () => {
    dispatch(getPosts(endPage + 1, leagueId));
    setCurrentPage(endPage + 1);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    dispatch(getPosts(currentPage, leagueId, searchQuery));
  };

  return (
    <div className='news-page-wrapper'>
      <div className='news-search-bar-wrapper'>
        <ThemeProvider theme={darkTheme}>
          <FormControl sx={{ m: 2, minWidth: 120 }}>
            <InputLabel id='post-search-league-select-label'>League</InputLabel>
            <Select
              labelId='post-search-league-select-label'
              id='post-search-league'
              value={leagueId}
              label='League'
              onChange={(e) => {
                setLeagueId(e.target.value);
              }}
            >
              <MenuItem value={'Show All'} key={98}>
                Show All
              </MenuItem>
              <MenuItem value={'All'} key={99}>
                All
              </MenuItem>
              {leagues.map((league, index) => (
                <MenuItem value={league.id} key={index}>
                  {league.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 2 }} className='post-search-input-form'>
            <TextField
              id='search-input'
              placeholder='Search title'
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
              onKeyDown={(e) => {
                e.key === 'Enter' ? handleSearchSubmit(e) : null;
              }}
              InputProps={{
                endAdornment: (
                  <IconButton aria-label='search-button' onClick={handleSearchSubmit}>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
          </FormControl>
        </ThemeProvider>
      </div>
      <div className='news-post-list-wrapper'>
        <div className='news-prioritized-post-wrapper'>
          {notices.map((notice) => {
            return (
              <NoticeListItem key={notice.id} notice={notice} location={window.location.pathname} />
            );
          })}
        </div>
        {postsLoading ? (
          <Loader className='posts-loader' type='Oval' color='#ff5722' secondaryColor='#757575' />
        ) : !posts.length ? (
          <div className='no-posts-container'>
            <FeedbackIcon fontSize='large' />
            No Posts Yet!
          </div>
        ) : (
          posts.map((post) => {
            return <PostListItem key={post.id} post={post} location={window.location.pathname} />;
          })
        )}
      </div>
      <PostPagination
        startPage={startPage}
        endPage={endPage}
        currentPage={currentPage}
        isLast={isLast}
        onClickPrev={onClickPrev}
        onClickPage={onClickPage}
        onClickNext={onClickNext}
      />
    </div>
  );
};

export default News;
