import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { getPostDetail } from '../store/actions/postDetail';
import { Button } from '@mui/material';
import DOMPurify from 'dompurify';
import Loader from 'react-loader-spinner';
import './NewsDetail.scss';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import sponsorSelector from '../const/sponsorSelector';
import { CreatedISODate, CreatedISOTime } from '../const/DatetimeISO';
import { clearPostDetail } from '../store/actions/postDetail';
import convertURLtoEmbed from '../components/Post/convertURLtoEmbed';
import attributes from '../const/domPurifyAttributes';

const NewsDetail = () => {
  const { postId } = useParams();
  const history = useHistory();
  const { postDetail, postLoading } = useSelector((store) => store.postDetailReducer);
  const { leagueCategory } = useSelector((store) => store.leagueReducer);
  const dispatch = useDispatch();
  const [localeDate, setLocaleDate] = useState('');
  const [localeTime, setLocaleTime] = useState('');
  const [sponsor, setSponsor] = useState(null);
  const [content, setContent] = useState('');

  useEffect(() => {
    dispatch(getPostDetail(postId));

    return () => {
      setContent('');
      dispatch(clearPostDetail());
      window.location.reload(); //refresh page for instagram embed
    };
  }, []);

  // Temporary timezone setting: created_at in response should be passed as datetime later
  useEffect(() => {
    if (postDetail) {
      setLocaleDate(CreatedISODate(postDetail));
      setLocaleTime(CreatedISOTime(postDetail));
      setContent(convertURLtoEmbed(postDetail.content));
      const sponsorObject = sponsorSelector(postDetail.league_abv);
      setSponsor(sponsorObject.postLogo);
    }
  }, [postDetail]);

  return (
    <main className='news-detail-page-container'>
      <div className='back-to-home-button-container'>
        <Button
          className='back-to-home-button'
          variant='contained'
          size='large'
          onClick={() => {
            history.push(`/${leagueCategory}/News`);
          }}
        >
          Go Back To List
        </Button>
      </div>
      {postLoading ? (
        <Loader className='post-loader' type='Oval' color='#ff5722' secondaryColor='#757575' />
      ) : (
        <div className='news-detail-container'>
          <div className='news-detail-title-wrapper'>
            <span className='news-title'>{postDetail?.title}</span>
            <span className='news-sponsor-logo'>
              <img src={sponsor} />
            </span>
          </div>
          <div className='news-detail-header-wrapper'>
            <div className='news-detail-author-abv-wrapper'>
              <span className='news-detail-author'>{postDetail?.username}</span>
              <span className='news-deatil-abv'>{postDetail?.league_abv ?? 'All'}</span>
            </div>
            <div className='news-detail-datetime'>{localeDate + ' ' + localeTime}</div>
          </div>
          <article className='news-detail-content-wrapper'>
            <div
              className='news-detail-content'
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(content, {
                  ADD_TAGS: ['iframe'],
                  ADD_ATTR: attributes,
                }),
              }}
            ></div>
          </article>
          <div className='news-detail-counts'>
            <div className='view-count-wrapper'>
              <VisibilityOutlinedIcon fontSize={'small'} />
              <span className='view-count'>{postDetail?.view_count} views</span>
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default NewsDetail;
