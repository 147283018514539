import React from 'react';
import './Error.scss';
import notFound from '../logo/error_page_404.png';

const Error = () => {
  return (
    <>
      <img src={notFound} className='not_found' alt='not_found' />
    </>
  );
};

export default Error;
