import React from 'react';
import './PostPagination.scss';

const PostPagination = ({
  startPage,
  endPage,
  currentPage,
  isLast,
  onClickPrev,
  onClickPage,
  onClickNext,
}) => {
  return (
    <div className='pagination-container'>
      {startPage !== 1 && (
        <button className='pagination-button' onClick={onClickPrev}>
          {'<'}
        </button>
      )}
      {Array(endPage - startPage + 1)
        .fill()
        .map((_, i) => {
          return (
            <button
              key={i}
              className={`pagination-button ${currentPage === startPage + i ? 'active' : ''}`}
              onClick={() => onClickPage(i)}
              disabled={currentPage === startPage + i}
            >
              {startPage + i}
            </button>
          );
        })}
      {!isLast && (
        <button className='pagination-button' onClick={onClickNext}>
          {'>'}
        </button>
      )}
    </div>
  );
};

export default PostPagination;
