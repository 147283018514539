import leagueReducer from './league';
import scheduleReducer from './schedule';
import mainScheduleReducer from './mainSchedule';
import matchReducer from './match';
import leagueStandingReducer from './leagueStanding';
import mainPageReducer from './mainPage';
import playerReducer from './player';
import statisticsReducer from './statistics';
import teamReducer from './team';
import { combineReducers } from 'redux';
import postReducer from './post';
import postDetailReducer from './postDetail';
import loginReducer from './login';
import userReducer from './user';

const rootReducer = combineReducers({
  leagueReducer,
  scheduleReducer,
  mainScheduleReducer,
  matchReducer,
  leagueStandingReducer,
  mainPageReducer,
  playerReducer,
  statisticsReducer,
  teamReducer,
  postReducer,
  postDetailReducer,
  loginReducer,
  userReducer,
});

export default rootReducer;
