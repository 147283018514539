import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import FindAccountInput from '../components/User/FindAccountInput';
import { findMyUsername, sendPasswordReset } from '../store/actions/user';
import './FindMyAccount.scss';

const FindMyAccount = () => {
  const dispatch = useDispatch();
  const [emailFind, setEmailFind] = useState('');
  const [emailSend, setEmailSend] = useState('');
  const [username, setUsername] = useState('');

  const handleFindId = (e) => {
    e.preventDefault();
    document.body.style.cursor = 'wait';
    dispatch(findMyUsername(emailFind));
    document.body.style.cursor = 'default';
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    document.body.style.cursor = 'wait';
    dispatch(sendPasswordReset(emailSend, username));
    document.body.style.cursor = 'default';
  };

  return (
    <div className='find-page-wrapper'>
      <div className='change-confidential-title'>Find My ID/Password</div>
      <div className='input-form-container'>
        <form className='input-form-item' onSubmit={handleFindId}>
          <FindAccountInput
            title='Find My ID'
            type='Email'
            onFirstInputChange={(value) => {
              setEmailFind(value);
            }}
          />
          <div className='input-button-wrapper'>
            <button className='input-button' type='submit'>
              Find User ID
            </button>
          </div>
        </form>
        <form className='input-form-item' onSubmit={handleResetPassword}>
          <FindAccountInput
            title='Send Reset Password Link to My Email'
            type='Email'
            onFirstInputChange={(value) => {
              setEmailSend(value);
            }}
          />
          <FindAccountInput
            type='Username'
            onFirstInputChange={(value) => {
              setUsername(value);
            }}
          />
          <div className='input-button-wrapper'>
            <button className='input-button' type='submit'>
              Send Password Reset Email
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FindMyAccount;
