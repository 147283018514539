import React from 'react';
import './League.scss';
import LeagueMenuItem from '../components/SubMenuItem';
import { Route, Switch } from 'react-router-dom';
import LeagueDetail from './LeagueDetail';
import LeagueSummary from './LeagueSummary';
import { useDispatch, useSelector } from 'react-redux';
import { getLeagues } from '../store/actions/league';
import { useEffect } from 'react';
const League = () => {
  const dispatch = useDispatch();
  const { leagues, leagueCategory } = useSelector((store) => store.leagueReducer);

  useEffect(() => {
    dispatch(getLeagues(leagueCategory));
  }, [leagueCategory]);

  return (
    <div className='league-wrapper'>
      <div className='league-menu-wrapper'>
        <LeagueMenuItem name='Summary' path={`/${leagueCategory}/Standings`} exact={true} />
        {leagues.map((league, index) => {
          return (
            <LeagueMenuItem
              key={index}
              name={league.abv}
              path={`/${leagueCategory}/Standings/${league.abv}`}
            />
          );
        })}
      </div>
      <div className='league-content-wrapper'>
        <Switch>
          <Route path='/:category/Standings' component={LeagueSummary} exact={true} />
          <Route path='/:category/Standings/:league' component={LeagueDetail} exact={true} />
        </Switch>
      </div>
    </div>
  );
};

export default League;
