import {
  GET_MATCH_DETAILS,
  GET_MATCH_DETAILS_SUCCESS,
  GET_MATCH_DETAILS_FAIL,
  GET_HOME_TEAM_ROSTER,
  GET_AWAY_TEAM_ROSTER,
  GET_HOME_CAPTAINS,
  GET_AWAY_CAPTAINS,
  GET_HOME_COACHES,
  GET_AWAY_COACHES,
  GET_MATCH_PHOTOS,
  GET_MATCH_PHOTOS_SUCCESS,
  GET_MATCH_PHOTOS_FAIL,
  CLEAR_MATCH,
} from '../actions/match';
const initialState = {
  matchDetails: null,
  homeTeamRoster: null,
  awayTeamRoster: null,
  homeCaptains: null,
  awayCaptains: null,
  homeCoaches: null,
  awayCoaches: null,
  matchphotos: null,
  isLoading: true,
  isError: false,
};

export default function matchReducer(state = initialState, action) {
  switch (action.type) {
    case GET_MATCH_DETAILS:
      return {
        ...state,
        isLoading: true,
      };
    case GET_MATCH_DETAILS_SUCCESS:
      return {
        ...state,
        matchDetails: action.data,
        isError: false,
        isLoading: false,
      };
    case GET_MATCH_DETAILS_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case GET_HOME_TEAM_ROSTER:
      return {
        ...state,
        homeTeamRoster: action.data,
      };
    case GET_AWAY_TEAM_ROSTER:
      return {
        ...state,
        awayTeamRoster: action.data,
      };
    case GET_HOME_CAPTAINS:
      return {
        ...state,
        homeCaptains: action.data,
      };
    case GET_AWAY_CAPTAINS:
      return {
        ...state,
        awayCaptains: action.data,
      };
    case GET_HOME_COACHES:
      return {
        ...state,
        homeCoaches: action.data,
      };
    case GET_AWAY_COACHES:
      return {
        ...state,
        awayCoaches: action.data,
      };
    case GET_MATCH_PHOTOS:
      return {
        ...state,
        isLoading: true,
      };
    case GET_MATCH_PHOTOS_SUCCESS:
      return {
        ...state,
        matchPhotos: action.data,
        isError: false,
        isLoading: false,
      };
    case GET_MATCH_PHOTOS_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case CLEAR_MATCH:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
