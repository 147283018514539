import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import UserForm from './UserForm';
import { signUp } from '../../store/actions/login';
import UserValidator from './UserValidator';
import Loader from 'react-loader-spinner';
import './Register.scss';

const Register = (props) => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [nickname, setNickname] = useState('');
  const [email, setEmail] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const userValidator = new UserValidator();

  const handleRegister = () => {
    if (!userValidator.validateRegisterForm(username, password, passwordConfirm, nickname, email)) {
      return;
    }
    setShowLoader(true);
    const name = firstName + ' ' + lastName;
    dispatch(signUp(username, password, name, nickname, email))
      .then(() => {
        props.onClick();
        alert(`Your account is created, but not activated yet. Please check your email inbox to verify your email.
It may take a few minutes to receive the email`);
      })
      .catch((err) => {
        // need to use Modal Component instead of alert in order to keep inputs after failed registration
        if (err.status === 500) {
          alert('Server Error! Please contact to official@sketchbball.com.');
        } else {
          err.status === 400 ? alert(err.data.detail) : alert(err.data.detail[0].msg);
        }
        setShowLoader(false);
      });
  };

  return (
    <>
      <div className='account-menu-wrapper'>
        <div className='account-menu'>
          <div className='account-menu-title'>About Sketch</div>
          <div className='about-sketch-text'>
            Sketch Community connects you to a welcoming group of sports lovers in Hong Kong.
            <br />
            Please be considerate of all users and be mindful that at Sketch we consider everyone to
            be part of our Sketch family.
          </div>
        </div>
      </div>
      <div className='register-form-wrapper'>
        <div className='register-form-title'>Create Account</div>
        {showLoader ? (
          <div className='register-loader-wrapper'>
            <Loader className='loader' type='Oval' color='#ff5722' secondaryColor='#757575' />
            <div className='wait-message'>
              Please Wait!
              <br />
              We are creating your account and sending a verification email to you...
            </div>
          </div>
        ) : (
          <div className='register-form'>
            <UserForm
              onUsernameChange={(value) => setUsername(value)}
              onPasswordChange={(value) => setPassword(value)}
              onPasswordConfirmChange={(value) => setPasswordConfirm(value)}
              onFirstNameChange={(value) => setFirstName(value)}
              onLastNameChange={(value) => setLastName(value)}
              onNicknameChange={(value) => setNickname(value)}
              onEmailChange={(value) => setEmail(value)}
            />
            <div className='register-button' onClick={handleRegister}>
              Create Account
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Register;
