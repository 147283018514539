import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './PostListItem.scss';
import sponsorSelector from '../../const/sponsorSelector';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { CreatedISODate, TodayISODate, CreatedISOTime } from '../../const/DatetimeISO';

const PostListItem = ({ post, location }) => {
  const history = useHistory();
  const todayDate = TodayISODate();

  // Temporary timezone setting: created_at in response should be passed as datetime later
  const localeDate = CreatedISODate(post);
  const localeTime = CreatedISOTime(post);

  const [sponsor, setSponsor] = useState(null);

  useEffect(() => {
    if (post !== undefined && post !== null) {
      const sponsorObject = sponsorSelector(post.league_abv);
      setSponsor(sponsorObject.postLogo);
    }
  }, [post]);

  return (
    <div
      className='post-item-container'
      onClick={() => {
        history.push(`${location}/${post.id}`);
      }}
    >
      <div className='post-item-wrapper'>
        {post.thumbnail_url ? (
          <div className='post-thumbnail'>
            <img src={post.thumbnail_url} />
          </div>
        ) : null}
        <div className='post-item'>
          <div className='post-league-wrapper'>
            <div className='post-league'>{post.league_abv ?? 'All'}</div>
          </div>
          <div className='post-title'>{post.title}</div>
          <div className='details-wrapper'>
            <span className='post-datetime'>
              {localeDate === todayDate ? localeTime : localeDate}
            </span>
            <span className='post-author'>{post.username}</span>
            <span className='post-view-count'>
              <VisibilityOutlinedIcon fontSize={'small'} />
              {post.view_count}
            </span>
          </div>
        </div>
      </div>
      <div className='post-logo'>
        <img src={sponsor} alt='sketch' />
      </div>
    </div>
  );
};

export default PostListItem;
