import React, { useState } from 'react';
import { Box, Modal } from '@mui/material';
import ModalBox from '../Common/ModalBox';
import { useDispatch } from 'react-redux';
import { createMatchstats } from '../../store/actions/match';
import './SelectPlayersModal.scss';
import modalStyle from '../../const/modalStyle';

const SelectPlayersModal = (props) => {
  const [open, setOpen] = useState(false);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [openWarning, setOpenWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  let players = [];

  if (props.roster?.length) {
    players = [...props.roster];
    players.sort((a, b) => {
      const aName = a.player_name.toLowerCase();
      const bName = b.player_name.toLowerCase();
      if (aName < bName) return -1;
      if (aName > bName) return 1;
      return 0;
    });
  }

  const handleOpenModal = () => {
    if (selectedPlayers.length === 0) {
      setOpenWarning(true);
      return;
    }
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setSelectedPlayers([]);
    props.onCancel();
  };

  const addPlayer = (e) => {
    const player = Object({
      seasonplayer_id: e.target.value,
      match_id: props.matchId,
    });
    if (!e.target.checked) {
      setSelectedPlayers(selectedPlayers.filter((item) => item.seasonplayer_id !== e.target.value));
      return;
    }
    setSelectedPlayers([...selectedPlayers, player]);
  };

  const confirmSelection = () => {
    dispatch(createMatchstats(selectedPlayers));
    setIsLoading(true);
  };

  const title = 'Confirm addition of players';
  const message = `Do you confirm to add ${selectedPlayers.length} players to the match?`;
  const warningMessage = `Please select at least 1 player to add to the match.`;

  return (
    <>
      <Modal open={props.open} onClose={handleCancel}>
        <Box sx={modalStyle}>
          <h3 className='modal-title'>{props.title}</h3>
          {openWarning && <p style={{ fontStyle: 'italic', color: '#ff0000' }}>{warningMessage}</p>}
          <div className='players-list'>
            {players?.map((player) => {
              if (player.name !== 'ETC')
                return (
                  <div className='player' key={player.id}>
                    <input
                      id={`player-name-${player.player_name}`}
                      type='checkbox'
                      value={player.id}
                      onChange={(e) => addPlayer(e)}
                    />
                    <label htmlFor={`player-name-${player.player_name}`}>
                      {player.player_name}
                    </label>
                  </div>
                );
            })}
          </div>
          <p style={{ fontStyle: 'italic', color: '#727272' }}>
            No need to add players if already in the match roster. If you have any enquiry, please
            contact us.
          </p>
          <div className='modal-button-wrapper'>
            <div className='button-connection' onClick={handleCancel}>
              Cancel
            </div>
            <div className='button-connection' onClick={handleOpenModal}>
              Confirm
            </div>
          </div>
        </Box>
      </Modal>
      <ModalBox
        open={open}
        onClose={handleCloseModal}
        title={title}
        message={message}
        onCancel={handleCloseModal}
        onConfirm={confirmSelection}
        loading={isLoading}
      />
    </>
  );
};

export default SelectPlayersModal;
