import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, NavLink } from 'react-router-dom';
import { validateToken } from '../../store/actions/login';
import AccountDetails from './AccountDetails';
import MyPlayer from './MyPlayer';
import './MyProfile.scss';

const MyProfile = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(validateToken());
  }, []);
  return (
    <>
      <div className='account-menu-wrapper'>
        <div className='account-menu'>
          <div className='account-menu-title'>Menu</div>
          <div className='profile-menu-item-wrapper'>
            <NavLink activeClassName='active' to='/profile' exact>
              Account Details &gt;
            </NavLink>
            <NavLink activeClassName='active' to='/profile/player'>
              My Player &gt;
            </NavLink>
          </div>
        </div>
      </div>
      <div className='profile-detail' style={{ minWidth: 300 }}>
        <Switch>
          <Route path='/profile' component={AccountDetails} exact />
          <Route path='/profile/player' component={MyPlayer} />
        </Switch>
      </div>
    </>
  );
};

export default MyProfile;
