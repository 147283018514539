import React, { useEffect, useState } from 'react';
import './Statistics.scss';
import { DataGrid } from '@mui/x-data-grid';
import { statPageDefaultHeader } from '../const/matchStatDefaultHeader';
import { FormControl, InputLabel, Select, MenuItem, TextField, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLeagues,
  getSeasons,
  getStats,
  getTeams,
  getStatsByName,
} from '../store/actions/statistics';

const Statistics = () => {
  const dispatch = useDispatch();
  const { leagueCategory } = useSelector((store) => store.leagueReducer);
  const { leagues, seasons, teams, stats, stats_loading } = useSelector(
    (store) => store.statisticsReducer,
  );
  const [leagueId, setLeagueId] = useState('');
  const [seasonId, setSeasonId] = useState('');
  const [teamId, setTeamId] = useState('All');
  const [matchType, setMatchType] = useState('League');
  const [searchQuery, setSearchQuery] = useState('');
  const [showGrid, setShowGrid] = useState(true);

  useEffect(() => {
    dispatch(getLeagues(leagueCategory));
  }, [leagueCategory]);

  useEffect(() => {
    if (leagues.length) {
      setLeagueId(leagues[0].id);
    }
  }, [leagues]);

  useEffect(() => {
    if (leagueId != '') {
      dispatch(getSeasons(leagueId));
    }
  }, [leagueId]);

  useEffect(() => {
    if (seasons.length) {
      setSeasonId(seasons[0].id);
    }
  }, [seasons]);

  useEffect(() => {
    if (seasonId != '') {
      dispatch(getTeams(seasonId));
      setTeamId('All');
    }
  }, [seasonId]);

  useEffect(() => {
    if (seasonId != '') {
      dispatch(getStats(seasonId, teamId, matchType));
      setShowGrid(true);
      setSearchQuery('');
    }
  }, [teamId, matchType, seasonId]);

  useEffect(() => {
    if (searchQuery != '') {
      setShowGrid(false);
    } else {
      setShowGrid(true);
    }
  }, [searchQuery]);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    dispatch(getStatsByName(searchQuery)); // I think we need to add leagueCategory in this api as well
    setShowGrid(true);
    if (searchQuery == '') {
      dispatch(getStats(seasonId, teamId, matchType));
    }
  };

  return (
    <div className='statistics-wrapper'>
      <div className='stat-search-bars-container'>
        <div className='stat-search-bar'>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id='demo-simple-select-helper-label'>League</InputLabel>
            <Select
              labelId='demo-simple-select-helper-label'
              id='demo-simple-select-helper'
              value={leagueId}
              label='League'
              onChange={(event) => {
                setLeagueId(event.target.value);
                setSeasonId('');
              }}
            >
              {leagues.map((item, index) => {
                return (
                  <MenuItem value={item.id} key={index}>
                    {item.abv}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id='demo-simple-select-helper-label'>Season</InputLabel>
            <Select
              labelId='demo-simple-select-helper-label'
              id='demo-simple-select-helper'
              value={seasonId}
              label='Season'
              onChange={(event) => {
                setSeasonId(event.target.value);
              }}
            >
              {seasons.map((item, index) => {
                return (
                  <MenuItem value={item.id} key={index}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id='demo-simple-select-helper-label'>Type</InputLabel>
            <Select
              labelId='demo-simple-select-helper-label'
              id='demo-simple-select-helper'
              value={matchType}
              label='Type'
              onChange={(event) => {
                setMatchType(event.target.value);
              }}
            >
              <MenuItem value={'League'}>League</MenuItem>
              <MenuItem value={'Playoff'}>Playoff</MenuItem>
              <MenuItem value={'Event'}>Event</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 210 }}>
            <InputLabel id='demo-simple-select-helper-label'>Teams</InputLabel>
            <Select
              labelId='demo-simple-select-helper-label'
              id='demo-simple-select-helper'
              value={teamId}
              label='Teams'
              onChange={(event) => {
                setTeamId(event.target.value);
              }}
            >
              <MenuItem value={'All'}>All</MenuItem>
              {teams.map((item, index) => {
                return (
                  <MenuItem value={item.id} key={index}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className='stats-search-by-name'>
          <FormControl sx={{ m: 2 }} className='stats-search-input-form'>
            <TextField
              id='stats-search-input'
              placeholder='Search Player'
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
              onKeyDown={(e) => {
                e.key === 'Enter' ? handleSearchSubmit(e) : null;
              }}
              InputProps={{
                endAdornment: (
                  <IconButton aria-label='stats-search-button' onClick={handleSearchSubmit}>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
          </FormControl>
        </div>
      </div>
      <div className='stat-data-grid'>
        {showGrid ? (
          <DataGrid
            rows={stats}
            columns={statPageDefaultHeader}
            autoHeight={true}
            rowHeight={30}
            pageSize={50}
            rowsPerPageOptions={[50]}
            disableColumnMenu={true}
            loading={stats_loading}
          />
        ) : (
          <div className='search-guide'>
            <span>Press Enter or click</span>
            <SearchIcon />
          </div>
        )}
      </div>
    </div>
  );
};

export default Statistics;
