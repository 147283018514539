import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import ModalBox from '../Common/ModalBox';
import { disconnectMyPlayer, getMyPlayer, updateMyPlayer } from '../../store/actions/user';
import defaultPlayerPhoto from '../../logo/default-player.png';
import './PlayerProfile.scss';
import PhotoInput from './PhotoInput';
import { updatePlayerPhoto } from '../../store/actions/player';
import { Button } from '@mui/material';

const PlayerProfile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoggedIn, userToken } = useSelector((store) => store.loginReducer);
  const { playerInfo } = useSelector((store) => store.userReducer);
  const { leagueCategory } = useSelector((store) => store.leagueReducer);
  const [playerWeight, setPlayerWeight] = useState('');
  const [playerHeight, setPlayerHeight] = useState('');
  const [playerQuote, setPlayerQuote] = useState('');
  const [playerBday, setPlayerBday] = useState('');
  const [playerProfilePhoto, setPlayerProfilePhoto] = useState('');
  const [openModal, setOpenModal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getMyPlayer(userToken?.id));
    }
  }, [isLoggedIn]);

  useEffect(() => {
    setPlayerWeight(playerInfo?.weight);
    setPlayerHeight(playerInfo?.height);
    setPlayerQuote(playerInfo?.quote);
    setPlayerBday(playerInfo?.birthdate);
    setPlayerProfilePhoto(playerInfo?.profile_photo);
  }, [playerInfo]);

  const handleClose = () => {
    setOpenModal(0);
  };

  const handleOpenModal = (type) => {
    setOpenModal(type);
  };

  const confirmDeactivate = () => {
    dispatch(disconnectMyPlayer());
  };

  const confirmChange = () => {
    setIsLoading(true);
    dispatch(
      updateMyPlayer(
        userToken?.id,
        playerWeight,
        playerHeight,
        playerQuote,
        playerBday,
        playerProfilePhoto,
      ),
    ).then(() => {
      if (imageFile) {
        dispatch(updatePlayerPhoto(playerInfo?.id, imageFile)).then(() => {
          history.go(0);
        });
      } else {
        history.go(0);
      }
    });
  };

  const deleteCurrentPhoto = () => {
    setPlayerProfilePhoto(null);
  };

  const handleProfilePhotoChange = (file) => {
    setImageFile(file);
  };

  const deactivateTitle = 'Confirm Player Deactivation';
  const deactivateMessage = 'Do you confirm to deactivate your player?';
  const changeTitle = 'Confirm Player Profile Change';
  const changeMessage = 'Do you confirm to change your player profile?';

  return (
    <div>
      <div className='player-profile-wrapper'>
        <div className='profile-detail-header'>Player Profile</div>
        <div className='player-profile-detail-wrapper'>
          <div className='player-profile-name'>
            <span className='player-detail-item-title'>{playerInfo?.hash_name}</span>
            <Link to={`/${leagueCategory ?? 'Sketch'}/players/${playerInfo?.id}`}>
              Click to view personal page
            </Link>
          </div>
          <div className='player-profile-detail'>
            <div className='player-detail-item-wrapper'>
              <div className='player-detail-item-title'>Weight (kg)</div>
              <input
                value={playerWeight ?? ''}
                onChange={(e) => {
                  setPlayerWeight(e.target.value);
                }}
              />
            </div>
            <div className='player-detail-item-wrapper'>
              <div className='player-detail-item-title'>Height (cm)</div>
              <input
                value={playerHeight ?? ''}
                onChange={(e) => {
                  setPlayerHeight(e.target.value);
                }}
              />
            </div>
            <div className='player-detail-item-wrapper'>
              <div className='player-detail-item-title'>Quote</div>
              <input
                value={playerQuote ?? ''}
                onChange={(e) => {
                  setPlayerQuote(e.target.value);
                }}
              />
            </div>
            <div className='player-detail-item-wrapper'>
              <div className='player-detail-item-title'>Birthdate</div>
              <input
                type='date'
                value={playerBday ?? ''}
                onChange={(e) => {
                  setPlayerBday(e.target.value);
                }}
              />
            </div>
            <div className='player-detail-item-wrapper'>
              <div className='player-detail-item-title'>Profile Photo</div>
              <div className='player-detail-item-content' id='profile-photo'>
                <img
                  src={playerProfilePhoto ?? defaultPlayerPhoto}
                  alt={playerProfilePhoto === null ? 'No Photo Yet' : 'Profile Photo'}
                />
                <div className='button-wrapper'>
                  <Button variant='contained' onClick={deleteCurrentPhoto}>
                    Delete Current Photo
                  </Button>
                  <span>
                    Please click <b>Save Changes</b> after deleting the current photo.
                  </span>
                </div>
              </div>
            </div>
            <div className='player-detail-item-wrapper'>
              <div className='player-detail-item-title'>
                Change
                <br />
                Profile Photo
              </div>
              <div className='player-detail-item-content'>
                <PhotoInput
                  onChange={(file) => {
                    handleProfilePhotoChange(file);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='player-detail-button-wrapper'>
          <div
            className='player-save'
            onClick={() => {
              handleOpenModal(1);
            }}
          >
            Save Changes
          </div>
          <div
            className='player-deactivate'
            onClick={() => {
              handleOpenModal(2);
            }}
          >
            Deactivate Player Status
          </div>
        </div>
        <div className='profile-notice'>
          Notice: Deactivating your player status will change your account type from “Player” to
          "Member". You will lose your access from your player profile and other related services.
        </div>
      </div>
      <ModalBox
        open={openModal === 1 ? true : false}
        onClose={handleClose}
        title={changeTitle}
        message={changeMessage}
        onCancel={handleClose}
        onConfirm={confirmChange}
        loading={isLoading}
      />
      <ModalBox
        open={openModal === 2 ? true : false}
        onClose={handleClose}
        title={deactivateTitle}
        message={deactivateMessage}
        onCancel={handleClose}
        onConfirm={confirmDeactivate}
        loading={isLoading}
      />
    </div>
  );
};

export default PlayerProfile;
