import React from 'react';
import SelectStartersButton from './SelectStartersButton';
import RosterProfile from './RosterProfile';
import './MatchStarters.scss';

const MatchStarters = (props) => {
  return (
    <div className={`${props.side.toLowerCase()}-starters`}>
      {(props.isAdmin || props.isCaptain) && (
        <SelectStartersButton
          matchId={props.matchId}
          roster={props.roster}
          team={props.team}
          side={props.side}
        />
      )}
      {props.team?.match_stats?.map((player, index) => {
        if (player.is_starter) return <RosterProfile key={index} player={player} index={index} />;
      })}
    </div>
  );
};

export default MatchStarters;
