import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { updateMyProfile } from '../../store/actions/user';
import UserValidator from './UserValidator';
import UserForm from './UserForm';
import ModalBox from '../Common/ModalBox';

const AccountDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userToken } = useSelector((store) => store.loginReducer);
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [nickname, setNickname] = useState('');
  const [email, setEmail] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userValidator = new UserValidator();

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleSubmit = () => {
    if (!userValidator.validateUpdateForm(username, nickname)) {
      return;
    }
    const name = firstName + ' ' + lastName;
    setIsLoading(true);
    dispatch(updateMyProfile(userToken?.id, username, name, nickname, email)).then(() => {
      history.go(0);
    });
  };

  const modalTitle = 'Confirm Profile Changes';
  const modalMessage = 'Do you confirm to change your profile?';

  return (
    <div className='account-details-wrapper'>
      <div className='account-details-title'>Account Details</div>
      <div className='account-details-container'>
        <UserForm
          onUsernameChange={(value) => setUsername(value)}
          onFirstNameChange={(value) => setFirstName(value)}
          onLastNameChange={(value) => setLastName(value)}
          onNicknameChange={(value) => setNickname(value)}
          onEmailChange={(value) => setEmail(value)}
        />
        <div className='account-details-button' onClick={handleOpenModal}>
          Save Changes
        </div>
      </div>
      <ModalBox
        open={openModal}
        onClose={handleClose}
        title={modalTitle}
        message={modalMessage}
        onConfirm={handleSubmit}
        onCancel={handleClose}
        loading={isLoading}
      />
    </div>
  );
};

export default AccountDetails;
