import React from 'react';
import './MenuItem.scss';
import { NavLink } from 'react-router-dom';

function MenuItem(props) {
  return (
    <NavLink
      className='menu-item'
      to={props.path}
      exact={props.exact ? props.exact : false}
      onClick={props.onClick}
      activeStyle={{
        color: props.activeColor ? props.activeColor : '#ff5722',
      }}
    >
      {props.title}
    </NavLink>
  );
}

export default MenuItem;
