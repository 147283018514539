import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MyProfile from '../components/User/MyProfile';
import Register from '../components/User/Register';
import './AccountPage.scss';

const AccountPage = () => {
  const history = useHistory();
  const { isLoggedIn } = useSelector((store) => store.loginReducer);
  const [isRegister, setIsRegister] = useState(false);

  useEffect(() => {
    if (isLoggedIn && !isRegister) {
      history.push('/profile');
    }
    if (isRegister) {
      history.push('/');
    }
  }, [isLoggedIn, isRegister]);

  return (
    <div className='account-page-container'>
      <div className='account-page-wrapper'>
        {isLoggedIn && !isRegister ? (
          <MyProfile />
        ) : (
          <Register
            onClick={() => {
              setIsRegister(true);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default AccountPage;
