import React from 'react';
import './Home.scss';
import HomeSchedule from '../components/Home/HomeSchedule';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import YoutubeEmbed from '../components/Common/YoutubeEmbed';
import StandingTable from '../components/StandingTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBanners,
  getMainNextGames,
  getMainPrevGames,
  getVideo,
  getAllLeagueStandings,
  clearMainPage,
} from '../store/actions/mainPage';
import { useEffect, useState } from 'react';

function Home() {
  const dispatch = useDispatch();
  const { banners, video, next_games, next_loading, prev_games, prev_loading, season_standings } =
    useSelector((store) => store.mainPageReducer);

  /** get stored category */
  const { leagueCategory } = useSelector((store) => store.leagueReducer);

  /** get data from server */
  useEffect(() => {
    if (leagueCategory) {
      dispatch(getBanners());
      dispatch(getMainNextGames(leagueCategory));
      dispatch(getMainPrevGames(leagueCategory));
      dispatch(getAllLeagueStandings(leagueCategory));
      dispatch(getVideo());
    }

    return () => {
      dispatch(clearMainPage());
    };
  }, [leagueCategory]);

  const [bannerDisplay, setBannerDisplay] = useState(null);
  const [standingDisplay, setStandingDisplay] = useState(null);

  const redirectShop = () => {
    window.location.href = 'https://sketchbball.myshopify.com';
  };

  useEffect(() => {
    setBannerDisplay(
      banners.length
        ? banners.map((item, index) => {
            return (
              <div key={index} className='clickable'>
                <img src={item.url} className='main-carousel-item' alt='main-carousel-item' />
              </div>
            );
          })
        : null,
    );
  }, [banners]);

  useEffect(() => {
    setStandingDisplay(
      season_standings.length
        ? season_standings.map((item, index) => {
            return (
              <div className='main-carousel-item' key={index}>
                <span>{item.league_name}</span>
                <StandingTable standings={item.league_standings} />
              </div>
            );
          })
        : null,
    );
  }, [season_standings]);

  return (
    <div className='home-wrapper'>
      <HomeSchedule title='Upcoming Matches' games={next_games} isLoading={next_loading} />
      <div className='home-carousel-wrapper'>
        {bannerDisplay ? (
          <Carousel
            showArrows={true}
            infiniteLoop={true}
            autoPlay={true}
            showThumbs={false}
            stopOnHover={true}
            onClickItem={() => redirectShop()}
          >
            {bannerDisplay}
          </Carousel>
        ) : null}
      </div>
      <HomeSchedule title='Game Results' games={prev_games} isLoading={prev_loading} />
      <div className='content-wrapper'>
        <div className='main-content-box'>
          <div className='main-grid-item-wrapper'>
            {standingDisplay ? (
              <Carousel showArrows={true} infiniteLoop={true} autoPlay={true} showThumbs={false}>
                {standingDisplay}
              </Carousel>
            ) : null}
          </div>
          <YoutubeEmbed embedId={video} />
        </div>
      </div>
    </div>
  );
}

export default Home;
