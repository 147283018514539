export const TodayISO = () => {
  const today = new Date();
  const todayISO = new Date(today.getTime() - today.getTimezoneOffset() * 60000).toISOString();
  return todayISO;
};

export const TodayISODate = () => {
  return TodayISO().slice(0, 10).replaceAll('-', '.');
};

export const CreatedISO = (post) => {
  const serverISO = post.created_at.date.replaceAll('.', '-') + 'T' + post.created_at.time + 'Z';
  const serverDatetime = new Date(serverISO);
  const localeDatetime = new Date(
    serverDatetime.getTime() - serverDatetime.getTimezoneOffset() * 60000,
  ).toISOString();
  return localeDatetime;
};

export const CreatedISODate = (post) => {
  return CreatedISO(post).slice(0, 10).replaceAll('-', '.');
};

export const CreatedISOTime = (post) => {
  return CreatedISO(post).slice(11, 16);
};

export const MatchLimitISO = (match) => {
  const matchLimitISO = match.registration_deadline
    ? match.registration_deadline.replace(' ', 'T')
    : match.scheduled_at.date.replaceAll('.', '-') + 'T' + '19:00:00Z';
  return matchLimitISO;
};
