import React, { useEffect } from 'react';
import { Switch, Route, useParams } from 'react-router-dom';
import Home from './Home';
import Schedule from './Schedule';
import League from './League';
import Match from './Match';
import PlayerDetail from './PlayerDetail';
import Statistics from './Statistics';
import TeamDetail from './TeamDetail';
import News from './News';
import NewsDetail from './NewsDetail';
import Error from './Error';
import { leagueCategories } from '../const/leagueCategories';
import { useDispatch } from 'react-redux';
import { getLeagueCategory } from '../store/actions/league';

const LeagueCategory = () => {
  const { category } = useParams();
  const dispatch = useDispatch();

  /** get league category from url and store in redux */
  useEffect(() => {
    dispatch(getLeagueCategory(category));
  }, [category]);

  return (
    <div className='category'>
      {!leagueCategories.includes(category) ? (
        <Switch>
          <Route component={Error} />
        </Switch>
      ) : category === 'Training' ? (
        <Switch>
          <Route path='/:category/Schedules' component={Schedule} exact />
          <Route path='/:category/teams/:teamId' component={TeamDetail} />
          <Route path='/:category/players/:playerId' component={PlayerDetail} />
          <Route path='/:category/matches/:id' component={Match} />
          <Route path='*' component={Error} />
        </Switch>
      ) : (
        <Switch>
          <Route path='/:category' component={Home} exact />
          <Route path='/:category/news' component={News} exact />
          <Route path='/:category/schedules' component={Schedule} />
          <Route path='/:category/standings' component={League} />
          <Route path='/:category/matches/:id' component={Match} />
          {/*<Route path="/:cateogry/sponsors" component={Sponsor} />*/}
          <Route path='/:category/statistics' component={Statistics} />
          <Route path='/:category/teams/:teamId' component={TeamDetail} />
          <Route path='/:category/players/:playerId' component={PlayerDetail} />
          <Route path='/:category/news/:postId' component={NewsDetail} />
          <Route path='/:category/*' component={Error} />
        </Switch>
      )}
    </div>
  );
};

export default LeagueCategory;
