import { Checkbox } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Loader from 'react-loader-spinner';
import './PlayerList.scss';
import SportsBasketballOutlinedIcon from '@mui/icons-material/SportsBasketballOutlined';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import { requestPlayerConnection } from '../../store/actions/user';
import { useHistory } from 'react-router-dom';
import ModalBox from '../Common/ModalBox';

const PlayerList = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedPlayerId, setSelectedPlayerId] = useState('');
  const [selectedPlayerName, setSelectedPlayerName] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const selectPlayerId = (e) => {
    e.target.checked ? setSelectedPlayerId(e.target.value) : setSelectedPlayerId('');
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleConfirm = (playerId) => {
    setIsLoading(true);
    dispatch(requestPlayerConnection(playerId))
      .then((res) => {
        alert(res.message);
        history.go(0);
      })
      .catch((err) => {
        alert(err.response.data.detail);
        setIsLoading(false);
        setOpenModal(false);
      });
  };

  const confirmTitle = 'Confirm Submission';
  const confirmMessage = (
    <p>
      Do you confirm your request to connect the player <strong>{selectedPlayerName}</strong> to
      your account?
    </p>
  );

  return (
    <div className='player-list-wrapper'>
      {props.isLoading ? (
        <Loader className='loader' type='Oval' color='#ff5722' secondaryColor='#757575' />
      ) : null}
      {props.players.map((player, index) => {
        return (
          <div className='player-list' key={index}>
            <Checkbox
              checked={selectedPlayerId == player.playerId}
              value={player.playerId}
              icon={<SportsBasketballOutlinedIcon fontSize='large' />}
              checkedIcon={<SportsBasketballIcon fontSize='large' sx={{ color: '#ee6730' }} />}
              onChange={(e) => {
                selectPlayerId(e);
                setSelectedPlayerName(player.player);
              }}
            />
            <div className='player-info'>
              <div className='player-name'>
                <span>Player:</span> {player.player}
              </div>
              <div className='player-team'>
                <span>Teams:</span>{' '}
                {player.teams.map((team, index) =>
                  player.teams.length - 1 === index ? team.name : team.name + ' | ',
                )}
              </div>
            </div>
          </div>
        );
      })}
      {selectedPlayerId && (
        <div className='player-request-button-wrapper'>
          <div className='button-connection' onClick={() => setOpenModal(true)}>
            Submit Request
          </div>
          <ModalBox
            open={openModal}
            onClose={handleCloseModal}
            title={confirmTitle}
            message={confirmMessage}
            onCancel={handleCloseModal}
            onConfirm={() => {
              handleConfirm(selectedPlayerId);
            }}
            loading={isLoading}
          />
        </div>
      )}
    </div>
  );
};

export default PlayerList;
