export const leagueCategories = [
  'Sketch',
  'Youth',
  'Corporate',
  'Korean',
  'Training',
  'Asian',
  'Alumni',
  'Sketch-Korea',
  'BLeague',
  'Others',
  'Premium',
];
