import React from 'react';
import './TeamNameButton.scss';
import { useHistory } from 'react-router';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';

const TeamNameButton = ({ ButtonStyle, ButtonSize, TeamName, TeamId }) => {
  const history = useHistory();
  const { leagueCategory } = useSelector((store) => store.leagueReducer);

  const teamClickHandler = (team_id) => {
    history.push(`/${leagueCategory}/Teams/${team_id}`);
  };

  return (
    <div>
      <Button
        sx={ButtonStyle}
        size={ButtonSize}
        variant='text'
        onClick={(e) => {
          e.stopPropagation();
          teamClickHandler(TeamId);
        }}
      >
        {TeamName}
      </Button>
    </div>
  );
};

export default TeamNameButton;
