import {
  GET_POST_DETAIL,
  GET_POST_DETAIL_SUCCESS,
  GET_POST_DETAIL_FAIL,
} from '../actions/postDetail';

const initialState = {
  postDetail: null,
  postLoading: true,
};

export default function postDetailReducer(state = initialState, action) {
  switch (action.type) {
    case GET_POST_DETAIL:
      return {
        ...initialState,
      };
    case GET_POST_DETAIL_SUCCESS:
      return {
        ...state,
        postDetail: action.data,
        postLoading: false,
      };
    case GET_POST_DETAIL_FAIL:
      return {
        ...state,
        postLoading: false,
      };
    default:
      return state;
  }
}
