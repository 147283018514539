const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  maxWidth: 500,
  bgcolor: '#ffffff',
  border: '2px solid #000',
  boxShadow: 24,
  pt: '1rem',
  px: '2rem',
  pb: '1.5rem',
};

export default modalStyle;
