import React, { useEffect, useState } from 'react';
import './TeamDetail.scss';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TeamSchedule from '../components/Team/TeamSchedule';
import TeamBanner from '../components/Team/TeamBanner';
import TeamRoster from '../components/Team/TeamRoster';
import TeamStats from '../components/Team/TeamStats';
import TeamAllTime from '../components/Team/TeamAllTime';
import TeamStarting from '../components/Team/TeamStarting';
import {
  getTeamSeasons,
  getTeamPlayerStats,
  getTeamStats,
  getTeamWLStats,
  getTeamAllTimeHigh,
  getTeamInfo,
  getTeamSchedule,
  getTeamPrevGames,
} from '../store/actions/team';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const TeamDetail = () => {
  const { teamId } = useParams();
  const dispatch = useDispatch();

  const {
    teamSeasons,
    teamSchedule,
    isScheduleLoading,
    teamPlayerStats,
    teamPrevGames,
    isPrevGamesLoading,
  } = useSelector((store) => store.teamReducer);

  const [teamSeasonId, setTeamSeasonId] = useState('');
  const [teamSeasonName, setTeamSeasonName] = useState('');

  useEffect(() => {
    dispatch(getTeamSchedule(teamId));
  }, [teamId]);

  useEffect(() => {
    if (teamId) {
      dispatch(getTeamSeasons(teamId));
    }
  }, []);

  useEffect(() => {
    if (teamId) {
      dispatch(getTeamInfo(teamId));
    }
  }, []);

  useEffect(() => {
    if (teamSeasons.length) {
      setTeamSeasonId(teamSeasons[0].id);
      setTeamSeasonName(teamSeasons[0].name);
    }
  }, [teamSeasons]);

  useEffect(() => {
    if (teamSeasonId != '' && teamId != '') {
      dispatch(getTeamPlayerStats(teamId, teamSeasonId));
      dispatch(getTeamPrevGames(teamId, teamSeasonId));
    }
  }, [teamSeasonId]);

  useEffect(() => {
    if (teamSeasonId != '' && teamId != '') {
      dispatch(getTeamStats(teamId, teamSeasonId));
    }
  }, [teamSeasonId]);

  useEffect(() => {
    if (teamSeasonId != '' && teamId != '') {
      dispatch(getTeamWLStats(teamId, teamSeasonId));
    }
  }, [teamSeasonId]);

  useEffect(() => {
    if (teamId) {
      dispatch(getTeamAllTimeHigh(teamId));
    }
  }, []);

  return (
    <div className='team-wrapper'>
      <div className='team-season-search-bar-container'>
        <div className='team-season-search-bar'>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id='demo-simple-select-helper-label'>{'Divison/Season'}</InputLabel>
            <Select
              sx={{ height: '2.5rem' }}
              labelId='demo-simple-select-helper-label'
              id='demo-simple-select-helper'
              value={teamSeasonId}
              label='League'
              onChange={(event) => {
                setTeamSeasonId(event.target.value);
              }}
            >
              {teamSeasons.map((item, index) => {
                return (
                  <MenuItem
                    value={item.id}
                    key={index}
                    onClick={() => setTeamSeasonName(item.name)}
                  >
                    {item.league_name + ' - ' + item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
      </div>
      <TeamBanner />
      <div className='team-alltime-starting'>
        <TeamAllTime />
        <TeamStarting />
      </div>
      <TeamSchedule title='Upcoming Matches' games={teamSchedule} isLoading={isScheduleLoading} />
      <TeamSchedule
        title={`Previous Matches - ${teamSeasonName}`}
        games={teamPrevGames}
        isLoading={isPrevGamesLoading}
      />
      <TeamRoster roster={teamPlayerStats} />
      <TeamStats />
    </div>
  );
};

export default TeamDetail;
