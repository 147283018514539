import React, { useEffect } from 'react';
import './App.scss';
import { Route, Switch } from 'react-router-dom';
import Header from './components/Common/Header';
import AccountPage from './pages/AccountPage';
import ChangeEmail from './pages/ChangeEmail';
import ChangePassword from './pages/ChangePassword';
import ResetPassword from './components/User/ResetPassword';
import FindMyAccount from './pages/FindMyAccount';
import Verify from './pages/Verify';
import LandingPage from './pages/LandingPage';
import LeagueCategory from './pages/LeagueCategory';
import Footer from './components/Common/Footer';
// Google Analytics
import ReactGA from 'react-ga4';
import RouteChangeTracker from './google-analytics/RouteChangeTracker';

const TRACKING_ID = 'G-MDBFQN83C5';
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.send('pageview');
  }, []);

  return (
    <div className='App'>
      <RouteChangeTracker />
      <Header />
      <Switch>
        <Route path='/' component={LandingPage} exact />
        <Route path='/profile' component={AccountPage} />
        <Route path='/register' component={AccountPage} />
        <Route path='/change/email' component={ChangeEmail} />
        <Route path='/change/password' component={ChangePassword} exact />
        <Route path='/reset/:token' component={ResetPassword} />
        <Route path='/find' component={FindMyAccount} />
        <Route path='/verify/:userId' component={Verify} />
        <Route path='/:category' component={LeagueCategory} />
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
