import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UserValidator from '../components/User/UserValidator';
import ChangeConfidential from '../components/User/ChangeConfidential';
import { changeMyPassword } from '../store/actions/user';
import { logOut } from '../store/actions/login';
import { useHistory } from 'react-router-dom';
import Loader from 'react-loader-spinner';

const ChangePassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userToken } = useSelector((store) => store.loginReducer);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [reNewPassword, setReNewPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const userValidator = new UserValidator();

  const handleOldPasswordChange = (oldPassword) => {
    setOldPassword(oldPassword);
  };

  const handleNewPasswordChange = (newPassword) => {
    setNewPassword(newPassword);
  };

  const handleReNewPasswordChange = (reNewPassword) => {
    setReNewPassword(reNewPassword);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    if (!userValidator.validatePasswordForm(newPassword, reNewPassword)) {
      return;
    }
    dispatch(changeMyPassword(userToken?.id, oldPassword, newPassword)).then((res) => {
      if (res) {
        dispatch(logOut());
        history.push('/');
      }
      setIsLoading(false);
    });
  };

  return (
    <div className='confidential-page-wrapper'>
      {isLoading ? (
        <Loader className='post-loader' type='Oval' color='#ff5722' secondaryColor='#757575' />
      ) : (
        <ChangeConfidential
          value='Password'
          onFirstInputChange={handleOldPasswordChange}
          onSecondInputChange={handleNewPasswordChange}
          onThirdInputChange={handleReNewPasswordChange}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default ChangePassword;
